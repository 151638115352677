@import './../../styles/colors.scss';
@import './../../styles/mixin.scss';

button {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: $color-blue-medium;
	border: none;
	position: fixed;
	bottom: 50px;
	right: 20px;
	z-index: 1;
	display: none;
	cursor: pointer;

	.material-icons-outlined.md-30 { 
		font-size: 30px;
	}

	@media screen and (min-width: 1024px) {
		right: 30px;
	}
}

@media (hover) {
	button:hover{
		background-color: $color-blue-light;
	}
}