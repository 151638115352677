@import './../../../styles/mixin.scss';

.awards {
	@include small-section;

	.award {
		@include flex-content(flex, column);
	
		&__title {
			@include font;
		}
	
		&__information {
			@include flex-content(flex, column);
			@include font(18px, lighter);
		}
	
		&__description {
			margin-right: 20px;
		}
	
		&__date {
			@include font(16px);
			margin: 5px 0;
		}
	}

	@media screen and (min-width: 1024px) {
		@include section;

		.award__information {
			@include flex-content(flex, row, space-between);
			@include font(18px, lighter);
		}

		.award__date {
			@include font(16px);
			margin: 0;
		}
	}
}