@import './colors';

@mixin flex-content ($display:flex, $flex-direction:row, $justify-content:unset, $align-items:unset){
	display: $display;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

@mixin font ($font-size: 20px, $font-weight: normal, $color: $color-black, $line-height: 1.5em) {
	font-size: $font-size;
	font-weight: $font-weight;
	color: $color;
	line-height: $line-height
}

@mixin small-section ($background-color: $color-white, $padding: 30px 20px 20px 20px, $border-bottom: solid 1px $color-gray-dark, $scroll-margin-top: 65px, $margin-bottom: 10px, $margin-left: 10px, $margin-right: 10px) {
	background-color: $background-color;
	padding: $padding;
	border-bottom: $border-bottom;
	scroll-margin-top: $scroll-margin-top;
	margin-bottom: $margin-bottom;
	margin-left: $margin-left;
	margin-right: $margin-right;
}

@mixin section ($background-color: $color-white, $border-style: solid, $border-width: 1px, $border-color: $color-black, $padding: 50px, $margin-bottom: 30px, $scroll-margin-top: 10px, $box-shadow: -10px 10px $color-black) {
	background-color: $background-color;
	border-style: $border-style;
	border-width: $border-width;
	border-color: $border-color;
	padding: $padding;
	margin-bottom: $margin-bottom;
	scroll-margin-top: $scroll-margin-top;
	box-shadow: $box-shadow;
}