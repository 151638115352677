@import './../../../styles/colors.scss';
@import './../../../styles/mixin.scss';

.competencies {
	@include small-section;

	p {
		@include font(20px, lighter);
	}

	&__list {
		list-style-type: none;
		@include flex-content(flex, column, center, flex-start);
	}

	&__item {
		@include flex-content(flex, row, flex-start, center);
		margin: 2px;
	}

	.competencie {
		@include font(18px, lighter);
		margin-left: 2px;
	}
	
	.material-icons-outlined.done {
		color: $color-blue-medium;
		font-weight: bolder;
	}

	@media screen and (min-width: 1024px) {
		@include section;

		&__list {
			flex-wrap: wrap;
			height: 100px;
		}
	}
}