@import './../../styles/colors.scss';
@import './../../styles/mixin.scss';

.navigator {
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	background-color: $color-blue-medium;
	border: solid 3px $color-black;

	&__list {
		list-style: none;	
		@include flex-content;
	}

	&__item {
		height: 50px;
	}

	&__image {
		width: 30px;
		border-radius: 50%;
		margin: 10px;
	}

	&__tooltip {
		display: none;
	}

	.material-icons-outlined { 
		font-size: 30px;
		margin: 10px;
	}

	&__anchor:link, &__anchor:visited {
		color: inherit;
		text-decoration: none;
		cursor: pointer;
	}
	
	@media screen and (min-width: 400px) {
		overflow-y: unset;
		overflow-x: unset;

		&__list {
			@include flex-content(flex, row, center, center);
		}
	}

	@media screen and (min-width: 1024px) {
		width: auto;
		margin: 0 30px;
		position: -webkit-sticky;
		position: sticky;
		top: 60px;
		right: 60px;
		box-shadow: -3px 3px $color-gray-light, -6px 6px $color-gray-medium, -9px 9px $color-gray-dark;

		&__list {
			@include flex-content(flex, column, center, center);
		}

		&__tooltip {
			display: inline;
			visibility: hidden;
			width: 150px;
			height: 35px;
			background-color: $color-white;
			border: solid 1px $color-gray-light;
			@include font(18px, lighter);
			text-align: center;
			padding: 5px 0;
			margin-top: 5px;
			position: absolute;
			z-index: 1;
			box-shadow: -5px 5px $color-gray-light;
			right: 130%;
			opacity: 0;
			transition-property: opacity;
			transition-duration: 0.3s;
			transition-timing-function: linear;
			transition-delay: 0.1s;
		}
		
		&__item:hover &__tooltip {
			visibility: visible;
			opacity: 1;
		}
	}

	@media screen and (min-width: 1550px) {
		&__tooltip {
			right: unset;
			left: 130%;
		}
	}
}

@media (hover) {
	.navigator__anchor:hover {
		color: transparentize($color: $color-black, $amount: 0.5)
	}
}