@import './colors.scss';

*, *::after, *::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
};

html, body {
	font-family: 'Josefin Sans', sans-serif;
	font-size: 20px;
}

body {
	background-image: linear-gradient( $color-blue-light, $color-blue-dark);
}