@import './../../../styles/colors.scss';
@import './../../../styles/mixin.scss';


.card {
	background-image: radial-gradient($color-black, 25%, transparent 10%);
	background-size: 15px 15px;
	margin: 10px 0;
	padding: 15px;

	&__content {
		@include flex-content(flex, column, center, center);
		width: 100%;
		height: 400px;
		background-color: $color-blue-medium;
	}

	&__image {
		width: 200px;
		border-radius: 50%;
		margin: 10px;
	}

	&__name {
		@include font(20px, bold);
		margin-top: 20px;
		margin-bottom: 10px;
	}

	&__profession {
		@include font(20px, lighter);
		color: $color-black;
		margin: 10px;
	}
	
	&__icons {
		margin: 10px;
		@include flex-content(flex, row, center, center);

		.fa-github {
			font-size: 22px;
			margin: 10px;
		}
		
		.fa-linkedin {
			font-size: 22px;
			margin: 10px;
		}
		
		a:link, a:visited {
			color: inherit;
			text-decoration: none;
			cursor: pointer;
		}
		
		a:hover, a:active {
			color: transparentize($color: $color-black, $amount: 0.5)
		}
	}

	@media screen and (min-width: 1024px) {
		margin: 0 30px;
		padding: 30px;
		padding-left: 75px;
		position: -webkit-sticky;
		position: sticky;
		top: 60px;
		
		&__content {
			width: 300px;
			border: solid 3px $color-black;
		}
	}
}