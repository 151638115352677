@import './../../../styles/colors.scss';
@import './../../../styles/mixin.scss';

.languages {
	@include small-section;

	p {
		@include font(20px, lighter);
	}

	.language {
		@include flex-content(flex, column);
		margin: 15px 0px;
		@include font(18px);
	
		&__levelDots {
			@include flex-content(flex, row, center, center);
			margin-right: 10px;
		}

		&__level {
			@include flex-content(flex, row, flex-start, center);
			@include font(18px, lighter);
			flex-wrap: wrap;
		}
	}
	
	.material-icons.md-light{
		font-size: 18px;
		color: $color-blue-medium;
		margin: 2px;
	}
	
	.material-icons-outlined.md-light{
		font-size: 18px;
		color: $color-blue-medium;
		margin: 2px;
	}

	@media screen and (min-width: 1024px) {
		@include section;
	}
}

