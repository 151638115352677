@import './../../styles/colors.scss';
@import './../../styles/mixin.scss';


.resume {
	@include flex-content(flex, column);
	width: 100%;

	h2 {
		margin-bottom: 20px;
	}

	&__navigator {
		display: none;
	}

	@media screen and (min-width: 1024px) {
		@include flex-content(flex, row, center, top);
	
		&__sections {
			max-width: 800px;
		}

		&__navigator {
			display: block;
		}
	}
}
