@import './../../../styles/colors.scss';
@import './../../../styles/mixin.scss';

.contact {
	@include small-section;

	&__item {
		@include flex-content(flex, row, flex-start, center);

		dt {
			margin: 5px;
		
			.material-icons-outlined {
				color: $color-blue-medium;
				font-size: 24px;
			}
			
			.fa-linkedin {
				color: $color-blue-medium;
				font-size: 24px;
			}
			
			.fa-github {
				color: $color-blue-medium;
				font-size: 24px;
			}	
		}
		
		dd {
			margin: 5px 15px;
			@include font(20px, lighter);
	
			a:link, a:visited {
				color: inherit;
				text-decoration: none;
				cursor: pointer;
			}
			
			a:hover, a:active {
				color: transparentize($color: $color-black, $amount: 0.5)
			}
		}
	}

	@media screen and (min-width: 1024px) {
		@include section;
	}
}