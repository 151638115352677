@import './../../../styles/colors.scss';
@import './../../../styles/mixin.scss';

.blog {
	@include small-section;
	
	p {
		@include font(20px, lighter);
	}

	&__link {
		@include flex-content(flex, row, center, center);
		margin-top: 20px;
	}

	&__anchor:link, &__anchor:visited {
		background-color: $color-blue-medium;
		@include font;
		padding: 10px 20px;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		border-radius: 5px;
	}

	@media screen and (min-width: 1024px) {
		@include section;
	}
}

@media (hover) {
	.blog__anchor:hover{
		background-color: $color-blue-light;
	}
}