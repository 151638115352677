@import './../../styles/mixin.scss';

.header {
	width: 100%;
	height: auto;

	&__logo {
		width: 100%;
		height: 100%;
		padding-top: 15px;
		padding-bottom: 10px;
		@include font(28px, lighter);
		@include flex-content(flex, row, center, center);
	}

	&__navigator {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
	}

	@media screen and (min-width: 426px) {

		&__logo {
			padding-left: 50px;
			@include flex-content;
		}

		&__navigator {
			@include flex-content(flex, row, center, center);
		}
	}

	@media screen and (min-width: 1024px) {
	
		&__logo {
			padding-top: 25px;
			padding-bottom: 15px;
			@include font(32px, lighter);
		}

		&__navigator {
			display: none;
		}
	}
}